// Application constants
export const serverUrl = {
  url: "https://passenger.sharmajiclasses.com/",
  // url: "http://localhost:5000/",
};

export const appConstants = {
  newBooking: "New Booking",
  profile_incomplete: "Please complete your profile",
  myBookings: "My Bookings",
  myProfile: "My Profile",
  purchaseOC: "Apply for Oyster Cards",
  addDebitCreditCard: "Add Debit/Credit Card",
  requestSeasonTkt: "Request Season Ticket",
  requestFlexiTkt: "Request For Flexi Ticket",
  changePassword: "Change Password",
  finance: "Finance",
  aboutUs: "About Us",
  Investors: "Investors",
  financetitlefirst: "Revolving ",
  financetitlecolor: "Credit",
  financetitlelast: "Facility - Features",
  financelimitone: "Facility limits of between  £100,000 - £1 million",
  financelimittwo:
    "Defer your Freight costs, VAT & Duty associated with your supplier payment",
  financelimitthree:
    "No set up costs or ongoing fees, you only pay for the facility when you use it",
  financelimitfour: "The facility is unsecured",
  financeworks: "How our financing works:",
  financelist: "Are you looking for a new career challenge?",
  financelisttwo: "Do you believe in the power of data?",
  financelistthree:
    "Are you ready to join a company intent on shaking things up ?",
  letstarted: "let's get started",
  WhoWeAretitleone: "Who",
  WhoWeAretitletwo: "We",
  WhoWeAretitlethree: "Are",
  aboutdata:
    "At The Passenger Hub we take a unique approach to travel. Our pioneering platform allows you to fnd the fastest, cheapest and best travel options by train or by a bus to hundred of cities, towns and villages across United Kingdom. That translates into savings - on money and time - for you!",
  history: "History",
  historytext:
    "In 2020, our founder, Oliver Eyitayo, embarked on an extensive backpacking trip across United Kingdom.",
  textabout:
    "Oliver knew where he wanted to go, but planning the best route, getting an afordable bus ticket or the right train to get him there left him frustrated and a little light in the pocket. Seeing a need for a multi-mode travel site, Oliver quit his job, packed up his life and moved to Manchester.",
  early: "In early 2020",
  earlytext:
    "We wanted to create a brand that would better connect with our users. Why The Passenger Hub? It's strong, it's fresh, it's easily pronounceable. We also chose a unique colour palette that is comforting and calming and added bespoke illustrations to evoke the beauty of travel.",
  earlytexttwo:
    "Although we've adopted these changes, we still remain committed to our mission of being the best platform for all your train and bus booking needs.",
  earlytextthird:
    "Today, The Passenger Hub operates in many cities million of monthly users from all 69 cities connect with many travel partners to get to any destination across United Kingdom.",
  team: "Team",
  Careers: "Careers",
  Roles: "Careers at The Passenger Hub",
  titlerolesone: "What",
  titlerolestwo: "We’re",
  titlerolethree: "Current roles",
  technology: "Technology",
  operation: "Operations",
  bussiness: "Bussiness Development",
  softdata: "(Software Engineering, Data Science Product & UX)",
  responsible: "Responsible",
  thinkbig: "Thinking Big",
  auth: "Authentic & Inclusive",
  debate: "Debate & Commit",
  together: "Get It Done, Together",
  insist: "Insist On The Higest Standards",
  press: "Press",
  bonus: "You get &10. Your friends get £10.",
  bonust: "The Travel Bonus that takes everyone further.",
  terms: "Terms Of Use",
  privacy: "Privacy Policy",
  frequent: "Frequently",
  Asked: "Asked",
  Questions: "Questions",
  faq: "FAQ",
  phonenumber: "Phone Number",
  EmailAddress: "Email Address",
  message: "Message",
  submit: "Submit",
  contact: "Contact Us",
  contactfirst: "Get In Touch",
  contactsecond: "With Us",
  TicketType: "Ticket Type",
  StationName: "Station Name",
  Date: "Date",
  Time: "Time",
  ChildrenCount: "Children (5-15)",
  AdultCount: "Adults (16+)",
  Leave: "Leave",
  Search: "Search",
  single: "Single",

  return: "Return",
  seasonticket: "Season Ticket",
  flexiticket: "Flexi Ticket",
  source: "Enter Origin Station",
  destination: "Enter Destination Station",
  dateformat: "dd/mm/yyyy",
  timeformat: "hh:mm",
  new: "New",
  booking: "Booking",
  trainbooking: "Train Bookings",
  tubebooking: "Tube Bookings",
  LongDurationBookings: "Weekly/Monthly/Yearly",
  OneDayBookings: "One Day Bookings",
  activebooking: "Active Bookings",
  futurebookings: "Future Bookings",
  pastbookings: "Past Bookings",
  bookingsdetails: "Booking Details",
  bookingtexttitle: "Booking",
  bookingtextdetaisl: "Details",
  TicketID: "Ticket ID",
  trainnumber: "Train Name/Number",
  sourcestationname: "Source Station Name",
  destinationstationname: "Destination Station Name",
  LeaveAfterTime: "Leave After Time",
  Bookingdate: "Booking Date",
  TicketPrice: "Ticket Price",
  seatnumber: "Seat Number",
  oriented: "Customer Oriented",
  inclusive: "Inclusive",
  visionary: "Visionary",
  collaborative: "Collaborative",
};
