const images = {
  logo: require("../images/logo.png"),
  plus: require("../images/plus_five.png"),
  floatLogo: require("../images/flat_logo.png"),
  chatfloatLogo: require("../images/chatGroup.png"),
  user12: require("../images/user.png"),
  logohome: require("../images/logoone.png"),
  oyster: require("../images/oyster11.jpg"),
  travel: require("../images/travel11.png"),
  // oyster: require('../images/oyster.gif'),
  // travel: require('../images/travel.gif'),
  shopping: require("../images/shopping.jpg"),
  banner1: require("../images/banner1.png"),
  banner2: require("../images/banner2.png"),
  banner3: require("../images/banner3.png"),
  border1: require("../images/border1.png"),
  mobile: require("../images/mobile.png"),
  background: require("../images/background.png"),
  box1: require("../images/box1.png"),
  cashpay1: require("../images/cash-pay1.png"),
  global1: require("../images/global1.png"),
  maskvalue: require("../images/mask-value.png"),
  ticket: require("../images/ticket.png"),
  bag: require("../images/bags.png"),
  heart: require("../images/heart.png"),
  cursor: require("../images/cursor1.png"),
  fb: require("../images/fb 1.png"),
  tw: require("../images/tw 1.png"),
  youtube: require("../images/youtube 1.png"),
  mobilecut: require("../images/mobile-cut.png"),
  taxicut: require("../images/taxi.png"),
  location: require("../images/location 1.png"),
  choose: require("../images/choose 1.png"),
  train1: require("../images/train 1.png"),
  cycle: require("../images/cycle.png"),
  passenger: require("../images/passenger.png"),
  // link: require("../images/lin.png"),
  img: require("../images/img.png"),
  img1: require("../images/img1.png"),
  img2: require("../images/img2.png"),
  img3: require("../images/img3.png"),
  finance: require("../images/finance.png"),
  tech12: require("../images/tech12.png"),
  development: require("../images/development 1.png"),
  operation: require("../images/operation 2.png"),
  plan1: require("../images/plan1.png"),
  plan2: require("../images/plan2.png"),
  plan3: require("../images/plan3.png"),
  plan4: require("../images/plan4.png"),
  plan5: require("../images/plan5.png"),
  plan6: require("../images/plan6.png"),
  gifticon: require("../images/gift-icon1.png"),
  banker: require("../images/banker.png"),
  eye: require("../images/eyee.png"),
  arrow: require("../images/arrow.png"),
  up: require("../images/drop-1.png"),
  view: require("../images/view.png"),
  train: require("../images/train.png"),
  recent: require("../images/recent.png"),
  headerlogo2: require("../images/headerlogo2.png"),
  top: require("../images/top.png"),
  text_1: require("../images/text_1.png"),
  el_phone: require("../images/el_phone.png"),
  entypo_address: require("../images/entypo_address.png"),
  wpf_name1: require("../images/wpf_name.png"),
  cancel: require("../images/ic_round-cancel.png"),
  loginimage: require("../images/login-image.png"),
  thankyou: require("../images/thankyou.png"),
  trainout: require("../images/train-out.png"),
  shiptruck: require("../images/shiptruck.png"),
  tool: require("../images/tool.png"),
  catchpa: require("../images/catchpa.png"),
  back: require("../images/back.png"),
  unmask: require("../images/unmask.png"),
  line_t: require("../images/line_t.png"),
  Register_img: require("../images/Group1398.png"),
  linkdin: require("../images/lin.png"),
  first_f: require("../images/first_f.png"),
  second_f: require("../images/second_f.png"),
  mask_1: require("../images/mask_1.jpeg"),
  mask_2: require("../images/mask_2.jpeg"),
  about1: require("../images/about2.png"),
  about2: require("../images/about1.jpg"),
  contact: require("../images/contact.jpeg"),
  price: require("../images/chart.jpg"),
  finance1: require("../images/finance1.png"),
  payment: require("../images/payments.png"),
  payment1: require("../images/payment1.png"),
  payment2: require("../images/payment2.png"),
  payment3: require("../images/payment3.png"),
  payment4: require("../images/payment4.png"),
  payment5: require("../images/payment5.png"),
  payment6: require("../images/payment6.png"),
  payment7: require("../images/payment7.png"),
  chat: require("../images/chat.png"),
  tik: require("../images/tiktok.png"),
  Chip: require("../images/Chip.png"),
  insta: require("../images/instagram.png"),
  link: require("../images/linkdinn.png"),
  mediaCardImg:require('../images/MediaCard.jpg')
};

export default images;
