import React from "react";
import GooglePayButton from "@google-pay/button-react";
import { useNavigate } from "react-router";

const Gpay = ({ amount, currency = "USD", bookingType, dispatchFn }) => {
  const navigate = useNavigate();

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "example",
            gatewayMerchantId: "exampleGatewayMerchantId",
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: "12345678901234567890",
      merchantName: "Demo Merchant",
    },
    callbackIntents: ["PAYMENT_AUTHORIZATION"],
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: `${amount}`,
      currencyCode: "USD",
      countryCode: "US",
    },
  };

  function handleLoadPaymentData(paymentData) {
    console.log("load payment data", paymentData);
  }
  let shouldSucceed = true;

  function hasSufficientFunds() {
    // TODO: implement funds check
    const promise = Promise.resolve(shouldSucceed);
    shouldSucceed = !shouldSucceed;
    return promise;
  }

  return (
    <div className="buttons">
      <GooglePayButton
        paymentRequest={paymentRequest}
        onLoadPaymentData={handleLoadPaymentData}
        onError={() => window.alert("Error")}
        onPaymentAuthorized={async (paymentData) => {
          console.log(paymentData, "pay data");
          if (await hasSufficientFunds()) {
            await dispatchFn();
            // navigate("/myBookings");
            //
            return { transactionState: "SUCCESS" };
          } else {
            return {
              transactionState: "ERROR",
              error: {
                reason: "PAYMENT_DATA_INVALID",
                message: "Insufficient funds!",
                intent: "PAYMENT_AUTHORIZATION",
              },
            };
          }
        }}
      />
      {/* <GooglePayButton
        buttonType="short"
        paymentRequest={paymentRequest}
        onLoadPaymentData={handleLoadPaymentData}
      />
      <GooglePayButton
        buttonColor="white"
        paymentRequest={paymentRequest}
        onLoadPaymentData={handleLoadPaymentData}
      />
      <GooglePayButton
        buttonType="short"
        buttonColor="white"
        paymentRequest={paymentRequest}
        onLoadPaymentData={handleLoadPaymentData}
      /> */}
    </div>
  );
};

export default Gpay;
