import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Layout, Menu } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';
import images from "../../themes/appImage";

import { Collapse, Select, DatePicker, Space } from "antd";
import MobileSidebar from "../../common/mobilesidebar";
import HeaderMain from "../../common/header";
import { getMemoizedAuthenticationData } from "../../redux/selectors/authentication";
import { drawerAction } from "../../redux/actions/authentication";
import FooterMain from "../../common/footer";
import Icon1 from "../../common/icon";
import "../../css/trainticekt.css"
import { Link } from "react-router-dom";
import InfoCard from "../../common/InfoCard";
import { minWidth } from "@mui/system";
import ImgCard from "../../common/ImgCard";
import ButtonCard from "../../common/ButtonCard";
import TrainCard from "../../common/TrainCard";
import RailCards from "../../common/RailCards";
import { Margin, WidthFull } from "@mui/icons-material";

// import ButtonCard from "../../common/ButtonCard";

const { Option } = Select;
const RailCardGuide = (props) => {
  const [WrapperDisplay, setWrapperDisplay] = useState("none")
  console.log("welcome investors screen.");
  const dispatch = useDispatch();
  const authenticationData = useSelector(getMemoizedAuthenticationData);
  const { drawerState } = authenticationData;
  const handlewClick = () => {
    let action = drawerState ? false : true;
    dispatch(drawerAction(action, "drawerState"));
  };
  const { Panel } = Collapse;
  useEffect(() => {
    document.title = "The PassengerHub";
    window.scrollTo(0, 0);
  }, []);
  const text = (
    <div className="text-model">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing
        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
        ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet,
        consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
        exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat.Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
        ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
        aliquip ex ea commodo consequat.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.Lorem ipsum dolor sit amet, consectetur adipisicing
        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
        ut aliquip ex ea commodo consequat.
      </p>
    </div>
  );




  const text1 = (
    <div className="text-model">
      <p>
      A:
Digital Railcards which allow you to purchase discounted tickets for eligible train services. Digital Railcards can be used to save money on tickets. We welcome the use of eligible digital Railcards when you are buying your train tickets with us. These can be applied to your purchase within our booking service. There are a number of digital Railcards that you can use in Great Britain.
      </p>

    </div>
  );

  const text2 = (
    <div className="text-model">
      <p>
      A:
Regardless of whether your Railcard was purchased from TrainPal or another platform, you can add it when searching for tickets.
      </p>

    </div>
  );

  const text3 = (
    <div className="text-model">
      <p>
        A:
        Once you've purchased a Railcard using the TrainPal app, tap "Download" to store your Railcard on your device. After applying your Railcard to a booking, sign in to your account and show your Railcard to the train conductor when asked. If you purchased a Railcard from another platform, carry it with you and show it when asked.
      </p>

    </div>
  );
  const text4 = (
    <div className="text-model">
      <p>
        A:
        No. Unfortunately, once a railcard has been issued it is non-refundable.
      </p>

    </div>
  );
  const text5 = (
    <div className="text-model">
      <p>
        A:
        In the unfortunate scenario where you lose your device, you can simply download your digital Railcard to a new device.
      </p>

    </div>
  );
  const text6 = (
    <div className="text-model">
      <p>
        A:
        If we detect suspicious activity on your Railcard, it will be blocked until we have had the chance to speak to you.

        During this period, your Railcard will no longer be valid for travel.

        If your Railcard has been blocked, you will need to contact us so that we can better understand the activity that has caused your Railcard to be blocked.

        Please follow these four easy steps:

        Step 1: Login to our app


        Step 2: Find your blocked Railcard.

        Step 3: Select ‘Contact us’.

        Step 4: Describe your circumstances and submit.
      </p>

    </div>
  );
  const text7 = (
    <div className="text-model">
      <p>
        A:
        It's super easy! If your Railcard is expiring in the next 30 Days, or has already expired you will see a button to "Renew" in TrainPal APP. Click on "Renew" and follow the steps, you can renew your Railcard with a couple of clicks. You won't need to type in your ID number or upload your photo again ( except for Two Together Railcard holder want to change the second card holder).
      </p>

    </div>
  );


  const text8 = (
    <div className="text-model">
      <p>
        A:
        Senior/Network/Family Railcards: You can renew your Railcard if the card status is Action or Expired.

        16-17 Saver: If you are still 17, you can renew your 16-17 Saver online. Your 16-17 Saver will be valid up until the day before your 18th birthday.However, if you are approaching your 18th birthday, then you may want to consider purchasing the 16-25 Railcard as it may provide you discounts for a longer period.

        16-25 Railcard: You can renew your Railcard if the valid date of your current card is before your 26th Birthday. If you are not aged 16-25 currently, you are not eligible to renew 16-25 Railcard. However, you can consider phurchase a 26-30 Railcards.

        26-31 Railcard: You can renew your Railcard if the valid date of your current card is before your 31th Birthday. If you are not aged 26-31 currently,you are not eligible to renew 26-30 Railcard. However, you can consider phurchase Two Together, Family or Network Railcards.
      </p>
    </div>
  );
  return (
    <div>
      <MobileSidebar isVisible={drawerState} handlewClick={handlewClick} />
      <HeaderMain handleClick={handlewClick} />
      <div className="mobileabout text-item">
        <div className="container-fluid spacert">
          <h3>Rail Card Guide</h3>
        </div>
      </div>
      <div className="cheap-train-tickets ">
        <div className="container">
      <div className="top-header text-center mt-3" style={{fontSize:"32px",fontWeight:"800"}}>Why should you get a digital Railcard?</div>
          <div className="row my-2 mt-5" style={{justifyContent:"space-between"}}>
            <ImgCard heading="Lower prices for digital Railcards" text="You can enjoy up to ￡3 off your digital Railcards when buying with the TrainPal App." image={images.train} />
            <ImgCard heading="1/3 off your rail travel" text="Get 1/3 off your UK train tickets immediately and save an average of ￡181 a year with a digital Railcard added to your TrainPal account." image={images.Chip} />
            <ImgCard heading="Travel further for less" text="Cheaper digital railcards, cheaper ticket prices by split ticketing, and 1/3 off rail fares with digital Railcards – all in the TrainPal App." image={images.location} />
          </div>
           </div>
 
      
       
        <div className="EVENING-OUT-2">
          <h3 className="my-4 text-center" style={{fontSize:"32px",fontWeight:"800"}}>Which is the right Railcard for you?</h3>
          <p className="my-2 text-center"><strong>
            Each Railcard offers a similar discount - 1/3 off the price of an adult fare, as well as some great discounts for children too. Choosing the right one for you depends on things like your age, where you live, and how you like to travel.</strong></p>
          <div className="container-fluid ">


          </div>
        </div>
        <div className="railcards">
          <div className="container-fluid">
            <div className="row my-2">
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto ">
                <RailCards CardHeading="16-25 Railcard" CardbackroundColor="rgb(237, 94, 0)" list1="Great for ages 16-25 solo travel" list2=" Save 1/3 on eligible rail-travel fares" list3="Average annual savings up to £189!" list4="3-year Railcard available to under 24s only" btn1text="Buy 3-Year from €70" btn2text="Buy 1-Year Rail Card From €70" btn3text="Learn More " />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="26-30 Railcard" CardbackroundColor="rgb(40, 50, 105)" list1=" Great for ages 26-30 solo travel" list2=" Save 1/3 on eligible rail-travel fares" list3="Average annual savings up to £251!" btn2text="Buy 1-Year Rail Card From €30" btn3text="Learn More " />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="26-30 Railcard" CardbackroundColor="rgb(223 60 47)" list1=" Great for ages 26-30 solo travel" list2=" Save 1/3 on eligible rail-travel fares" list3="Average annual savings up to £251!" btn2text="Buy 1-Year Rail Card From €30" btn3text="Learn More " />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="Two Together Railcard" CardbackroundColor="rgb(118, 60, 150)" list1=" For ages 16+ couples travel together" list2="Named passengers save 1/3 on eligible fares" list3="£15 each! Best discount for couple travel" btn2text="Buy 1-Year Rail Card From €30" btn3text="Learn More " />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="Family & Friends Railcard" CardbackroundColor="rgb(230, 5, 45)" list1="Great for families who love to trave" list2="1-4 adults save 1/3 on eligible fares, 1-4 children save 60%" btn1text="Buy 3-Year from €70" btn2text="Buy 1-Year Rail Card From €30" btn3text="Learn More " />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="Network Railcard" CardbackroundColor="rgb(0, 153, 209)" list1="Covers London and the South East" list2="1-3 adults save 1/3, 1-4 children save 60% on eligible fare" list3="Average annual savings up to £136!" btn2text="Buy 1-Year Rail Card From €30" btn3text="Learn More " />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="16-17 Saver" CardbackroundColor="rgb(254, 235, 25)" list1="For ages 16-17" subtitle1="Passengers get 50% discount on most tickets, including seasons" list2="Great for:" subtitle2="School and college students" btn1text="Learn More" btn2text="Buy 1-Year for 30" />
              </div>
              <div className="col-md-4 my-2 col-sm-10 col-12 mx-md-0 mx-auto">
                <RailCards CardHeading="Senior Railcard" CardbackroundColor="rgb(0, 87, 42)" list1="Great for ages 60+ solo travel" list2="Save 1/3 on eligible rail-travel fares" list3="Save 1/3 on eligible rail-travel fares" btn1text="Learn More" />
              </div>

            </div>
          </div>
        </div>
        <div className="rail-card-intro">
          <div className="container-fluid">
            <h3 className="text-center" style={{fontWeight:"700"}}>Railcard Introduction</h3>
            <div className="row">
              <div className="col-md-4">
                <h4 style={{fontWeight:"bold"}}>Railcard Type</h4>
                <p>There are seven types of UK Railcards available on the TrainPal app. These are:</p>
                <ol style={{ listStyle: "disc" }}>
                  <li className="my-1">Network Railcard</li>
                  <li className="my-1">16-17 Saver</li>
                  <li className="my-1"> 16-25 Railcard</li>
                  <li className="my-1"> 26-30 Railcard</li>
                  <li className="my-1">Family & Friends Railcard</li>
                  <li className="my-1">Two Together Railcard</li>
                  <li className="my-1"> Senior Railcard</li>
                </ol>
                <h4 style={{fontWeight:"bold"}}>About Price on the Trainpal App</h4>
                <p>All Railcards cost £30 for the whole year. Some Railcards are also available as a three-year option for £70, giving you even greater value for money (therefore costing around £23 per year).</p>
              </div>
              <div className="col-md-8 ">
                <table id='simple_table' >
                  <tr>
                    <th>Type	</th>
                    <th>Suitable for</th>
                    <th>Discount</th>
                    <th>1-year Price</th>
                    <th>3-year Price</th>


                  </tr>

                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td colSpan={5} style={{ color: "#98e2af", fontWeight: '600', fontSize: '12px' }}>Railcards by age </td>

                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>16-17 Saver</td>
                    <td>Ages 16-17</td>
                    <td>1/2 Off</td>
                    <td>￡30</td>
                    <td>-</td>



                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>16-25 Railcard</td>
                    <td>Ages 16-25</td>
                    <td>1/3 Off</td>
                    <td>￡30</td>
                    <td>￡70 save ￡20</td>



                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>26-30 Railcard</td>
                    <td>Ages 26-30</td>
                    <td>1/3 Off</td>
                    <td>￡30</td>
                    <td>-</td>



                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>Senior Railcard</td>
                    <td>Ages 60+</td>
                    <td>1/3 Off</td>
                    <td>￡30</td>
                    <td>￡70 save ￡20</td>



                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td colSpan={5} style={{ color: "#98e2af", fontWeight: '600', fontSize: '12px' }}>Railcards by group </td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>Two Together Railcard</td>
                    <td>• Ages 16+ <br />
                      • Must travel together</td>
                    <td>1/3off for 2 named people travel together</td>
                    <td>￡30
                      save £30 than buying seperately</td>
                    <td>	-</td>



                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>Family&Friends Railcard</td>
                    <td>• Up to 4 adults and4 children aged 5-15 <br />
                      • At least 1 adult and 1 child must travel together</td>
                    <td>1/3 off for adult & 60% off for kids</td>
                    <td>￡30
                      save up to £90 than buying separately</td>
                    <td>-</td>



                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td colSpan={5} style={{ color: "#98e2af", fontWeight: '600', fontSize: '12px' }}>Railcards by region </td>
                  </tr>
                  <tr style={{ borderBottom: '1px solid #98e2af' }}>
                    <td>Network Railcard</td>
                    <td>• Up to 4 adults and4 children aged 5-15 <br />
                      • Travellers in the South-East Network Railcard zone</td>
                    <td>1/3 off for adult & 60% off for kids in London and the South East of England</td>
                    <td>￡30</td>
                    <td>-</td>



                  </tr>




                </table>

              </div>
            </div>
          </div>
        </div>

        <div className="download-train text-center  mt-5" >
          <h2 style={{fontWeight:"700"}} >How to buy and use a digital Railcard on the TrainPal App?</h2>
          <p style={{fontWeight:"bold"}}><Link>Download TrainPal App </Link> and follow 6 easy steps to enjoy with your Railcard.</p>
        </div>

        <div className="container-fluid cards-data ">
          <div className="row">
            <div className="card-detail  d-flex flex-column justify-content-center align-items-center col-md-4">
              <img src="https://ak-d.tripcdn.com/images/0UC0w120009inydtrF65F.webp" alt="" className="img-fluid" />
              <h2 className="text-left w-100" style={{fontWeight:"bold"}}>Step 1</h2>
              <p>Open the TrainPal App and click "Railcard" at the bottom of the homepage</p>
            </div>
            <div className="card-detail  d-flex flex-column justify-content-center align-items-center col-md-4">
              <img src="https://ak-d.tripcdn.com/images/0UC2b120009inynij2944.webp" alt="" className="img-fluid" />
              <h2 className="text-left w-100" style={{fontWeight:"bold"}}>Step 2</h2>
              <p>Sign in to your TrainPal account on the app and select a Railcard that best suits you.</p>
            </div>
            <div className="card-detail d-flex flex-column justify-content-center align-items-center  col-md-4">
              <img src="https://ak-d.tripcdn.com/images/0UC61120009inyhvnF140.webp" alt="" className="img-fluid" />
              <h2 className="text-left w-100" style={{fontWeight:"bold"}}>Step 3</h2>
              <p>Choose "Buy alone", enter your first and last name (and date of birth),apply your promocode to enjoy the discount.</p>
            </div>
          </div>
        </div>

        <div className=" container-fluid cards-data row">
          <div className="card-detail  d-flex flex-column justify-content-center align-items-center col-md-4">
            <img src="https://ak-d.tripcdn.com/images/0UC0w120009inydtrF65F.webp" alt="" style={{ width: "90%" }} />
            <h2 className="text-left w-100" style={{fontWeight:"bold"}}>Step 4</h2>
            <p>Upload a digital passport-style photo for your Railcard from your phone, some cards also require verification of your ID</p>
          </div>
          <div className="card-detail  d-flex flex-column justify-content-center align-items-center col-md-4">
            <img src="https://ak-d.tripcdn.com/images/0UC2b120009inynij2944.webp" alt="" style={{ width: "90%" }} />
            <h2 className="text-left w-100" style={{fontWeight:"bold"}}>Step 5</h2>
            <p>You’ll find your Railcard in the Account tab of the app. Please make sure you're signed into the app with the same account you used to buy your Railcard</p>
          </div>
          <div className="card-detail  d-flex flex-column justify-content-center align-items-center col-md-4">
            <img src="https://ak-d.tripcdn.com/images/0UC61120009inyhvnF140.webp" alt="" style={{ width: "90%" }} />
            <h2 className="text-left w-100" style={{fontWeight:"bold"}}>Step 6</h2>
            <p>You’ll find your Railcard in the Account tab of the app. Please make sure you're signed into the app with the same account you used to buy your Railcard</p>
          </div>
        </div>

        <div className="digitals px-2">
          <div className="head-digi">
            <h2 className="digi-heading text-center" style={{fontWeight:"bold"}}>Why buy a digital Railcard on TrainPal?</h2>
          </div>
          <div className="px-3 digital-inner">
            <div className="logo-para d-flex" style={{ gap: "19px", margin: "16px 0px" }}>
              <img src="https://ak-d.tripcdn.com/images/0UC02120008x7mh4xC23D.png" alt="" style={{ width: "100px", height: "100px" }} />
              <p style={{ marginTop: "9px",fontWeight:"700" }}>Your can manage your train tickets and Railcards in one place by adding a digital Railcard to your TrainPal account. You won't need to switch between apps or dig around for your physical Railcard.</p>
            </div>
            <div className="logo-para d-flex">
              <img src="https://ak-d.tripcdn.com/images/0UC0m120008x7m3kp274C.png" alt="" style={{ width: "100px", height: "100px" }} />
              <p style={{ marginTop: "9px",fontWeight:"700" }}>When you add a digital Railcard to your TrainPal account, it can be stored on any devices you use. So you never worry about forgetting or losing your railcards.</p>
            </div>
            <div className="logo-para d-flex">
              <img src="https://ak-d.tripcdn.com/images/0UC5g120008x7m60u5083.png" alt="" style={{ width: "100px", height: "100px" }} />
              <p style={{ marginTop: "9px",fontWeight:"700" }}>TrainPal works hard to offer a one-stop service for our users' train travel. By adding a digital Railcard to your TrainPal account, you'll find your railcard is right where it needs to be, for example, when the ticket inspector is coming.</p>
            </div>
            <div className="logo-para d-flex">
              <img src="https://ak-d.tripcdn.com/images/0UC3t120008x7mdznB89A.png" alt="" style={{ width: "100px", height: "100px" }} />
              <p style={{ marginTop: "9px",fontWeight:"700" }}>Enjoy 1/3 off your train tickets as soon as you buy your digital Railcard on the TrainPal app. If you buy with your friends on the TrainPal app, you get up to ￡3 more off..</p>
            </div>
          </div>
        </div>



        <div className="download-detail mt-5" style={{ background: "#beebcccc" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <img src="https://ak-d.tripcdn.com/images/0AS1k12000a2xvbbyFC81.webp" alt="" style={{ width: "90%" }} />
              </div>

              <div className="col-md-6">
                <div className="app">
                  <h2 style={{ color: "#11475b", fontWeight: "800", fontSize: "43px", marginTop: "50px", textTransform: "capitalize" }}>Download TrainPal App</h2>
                  <p style={{ color: "rgb(9 187 157)", fontWeight: '800', fontSize: '22px' }}>Get £3 off on your Railcard purchase </p>
                  <p style={{ color: "rgb(237 251 255)", fontWeight: "900", width: "224px", padding: "10px 20px", background: " #11475b", borderRadius: "10px" }}>Use Code: RAILCARD</p>
                  <div className="container-fluid d-flex" style={{ marginLeft: "-36px" }} >

                    <img src=" https://uploads-ssl.webflow.com/5b4a11e42d24ef65774660bb/5e7f8ad0dc544571a7ed6923_AppStore_Icon-p-800.png " alt="" style={{ height: "70px" }} />
                    <img src="https://datastiq.com/wp-content/uploads/2019/07/google-play-badge-300x106.png" style={{ height: "47px", marginTop: "11px" }} />
                    <img src="https://www.hotlink.com.my/content/dam/hotlink/images/global-images/appgallery-btn.png" alt="" style={{ height: "70px" }} />
                  </div>
                </div>
                <div className="links">
                  .
                </div>
              </div>
            </div>
          </div>

        </div>



        <div className="container mt-5">
          <div className="faq-accordian">
            <h2 className="line">
              {/* {appConstants.frequent}
              <span className="color-d"> {appConstants.Asked}</span>{" "}
              {appConstants.Questions} */}
            </h2>
            {/* <div className="text-line">
                            <img src={images.border1} style={{ width: '400px' }} />
                        </div> */}
            <div className="accordion">
              <h1 className="header text-center" style={{fontSize:"32px",fontWeight:"700"}}>FAQ</h1>
              <Collapse accordion>
                <Panel
                  header="Q:
                  What are digital Railcards? "
                  key="1"
                >
                  <p>{text1}</p>
                </Panel>
                <Panel
                  header="Q:
                  How do I add a Railcard? "
                  key="2"
                >
                  <p>{text2}</p>
                </Panel>
                <Panel
                  header="Q:
                  How do I use a Railcard? "
                  key="3"
                >
                  <p>{text3}</p>
                </Panel>
                <Panel
                  header="Q:
                  Can I claim a refund if I don't use my Railcard? "
                  key="4"
                >
                  <p>{text4}</p>
                </Panel>
                <Panel
                  header="Q:
                  What happens if I lose my device?"
                  key="5"
                >
                  <p>{text5}</p>
                </Panel>
                <Panel
                  header="Q:
                  Why has my Railcard been blocked? "
                  key="6"
                >
                  <p>{text6}</p>
                </Panel>
                <Panel
                  header="Q:
                  How do I renew my Railcard online? "
                  key="7"
                >
                  <p>{text7}</p>
                </Panel>
                <Panel
                  header="Q:
Am I eligible to renew my Railcard?"
                  key="8"
                >
                  <p>{text8}</p>

                </Panel>
              </Collapse>
            </div>
          </div>
        </div>


       
     
     
       
      </div>
      <FooterMain />
      <Icon1 handleClick={handlewClick} />
    </div>
  );
};
export default RailCardGuide;
