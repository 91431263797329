import { AutoComplete, DatePicker, Select } from "antd";
import axios from "axios";
import React from "react";
import images from "../../themes/appImage";

import { Space, Table, Tag } from "antd";
import moment from "moment";
import HeaderMain from "../../common/header";
import MobileSidebar from "../../common/mobilesidebar";
import { getStationsListInitiate } from "../../redux/actions/newBookings";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getMemoizedNewBookingsData } from "../../redux/selectors/newBookings";
import { Option } from "antd/lib/mentions";

const GetTrainInfo = () => {
  const [train, setTrain] = React.useState({
    destination: "Stratford",
    origin: "Richmond",
    date: "2023-03-24T05:38:18+00:00",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newBookingsData = useSelector(getMemoizedNewBookingsData);

  const [availableTrains, setAvailableTrains] = React.useState([]);
  const [stationInfo, setStationInfo] = React.useState([]);
  const [liveStation, setLiveStation] = React.useState();
  const { stationListLoader, stationListSuccess, stationsList } =
    newBookingsData;
  const appKey = "bd91d003f7678656a36759356a09d7ca";
  const appId = "a1b00e73";

  React.useLayoutEffect(() => {
    dispatch(getStationsListInitiate({}, navigate));
  }, []);

  console.log(moment(train?.date).utc().format(), "train date booking");
  // console.log(newBookingsData, "new bookings data");
  const fetchTrains = async () => {
    await axios
      .get(
        // 2023-03-24T05:38:18+00:00
        `https://transportapi.com/v3/uk/train/station_timetables/${
          train?.origin
        }.json?app_id=${appId}&app_key=${appKey}&datetime=${moment(train?.date)
          .utc()
          .format()}&calling_at=${train?.destination}`
      )
      .then((res) => setAvailableTrains(res?.data?.departures))
      .catch(console.log);
  };

  const fetchStationInfo = async () => {
    await axios
      .get(
        // 2023-03-24T05:38:18+00:00
        `https://transportapi.com/v3/uk/train/station_timetables/${liveStation}.json?app_id=${appId}&app_key=${appKey}`
      )
      .then((res) => setStationInfo(res?.data))
      .catch(console.log);
  };

  const columns = [
    {
      title: "Date",
      render: () => <div>{moment(train?.date).format("MM-DD-YYYY")}</div>,
    },
    {
      title: "Arrival Time",
      dataIndex: "aimed_arrival_time",
    },
    {
      title: "Departure Time",
      dataIndex: "aimed_departure_time",
    },
    {
      title: "Platform No.",
      dataIndex: "platform",
    },
  ];
  const liveColumns = [
    {
      title: "Date",
      render: () => <div>{stationInfo?.date}</div>,
    },
    {
      title: "Departs",
      dataIndex: "aimed_departure_time",
    },
    {
      title: "Destination",
      dataIndex: "destination_name",
    },
    {
      title: "Platform No.",
      dataIndex: "platform",
    },
    { title: "Operator", dataIndex: "operator" },
  ];

  const onSearch = async () => {};
  console.log(availableTrains, train, stationInfo, "av train");
  return (
    <>
      <HeaderMain />
      <MobileSidebar isVisible={false} />
      <div className="mobileabout text-item">
        <div className="container-fluid spacert">
          <h3>Live train times</h3>
        </div>
      </div>

      <div
        style={{
          margin: "20px",
          display: "flex",
          columnGap: "20px",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            rowGap: "20px",
            flexDirection: "column",
          }}
        >
          <h3 style={{ textAlign: "center" }}>Check Route details</h3>

          <div style={{ display: "flex", columnGap: "32px", width: "50vw" }}>
            <div>
              <div style={{ fontSize: "24px", marginTop: "32px" }}>
                Enter departure station
              </div>
              <Select
                className="form-control station_search new_class"
                showSearch
                onSearch={onSearch}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                placeholder="Origin"
                style={{
                  width: 340,
                }}
                onChange={(e) => setTrain({ ...train, origin: e })}
              >
                {newBookingsData?.stationsList?.map((item, i) => (
                  <Option
                    key={i}
                    value={item?.StationCRSCode}
                    label={item?.StationName}
                  >
                    <div>{item.StationName}</div>
                  </Option>
                ))}
              </Select>
            </div>

            <div>
              <div style={{ fontSize: "24px", marginTop: "32px" }}>
                Enter arrival station
              </div>

              <Select
                className="form-control station_search new_class"
                showSearch
                onSearch={onSearch}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                // defaultValue="lucy"
                placeholder="Destination"
                style={{
                  width: 340,
                }}
                onChange={(e) => setTrain({ ...train, destination: e })}
              >
                {newBookingsData?.stationsList?.map((item, i) => (
                  <Option
                    key={i}
                    value={item?.StationCRSCode}
                    label={item?.StationName}
                  >
                    <div>{item.StationName}</div>
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          {/* // 2023-03-24T05:38:18+00:00 */}

          <div>
            <DatePicker
              disabledDate={(current) => {
                let customDate = moment().format("YYYY-MM-DD");
                return current && current < moment(customDate, "YYYY-MM-DD");
              }}
              style={{ width: "47%", marginTop: "24px" }}
              onChange={(e) =>
                setTrain({
                  ...train,
                  date: moment(e).format("yyyy-DD-MMThh:mm:ss+05:00"),
                })
              }
            />
          </div>
          <button
            className="button text"
            style={{ width: "250px" }}
            onClick={fetchTrains}
          >
            Submit
          </button>

          <Table columns={columns} dataSource={availableTrains?.all} />
        </div>
        <div
          style={{
            borderLeft: "1px solid #000",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3>Station Departures and Arrivals</h3>

          <div>
            <div style={{ fontSize: "24px", marginTop: "52px" }}>
              Enter station
            </div>
            <Select
              className="form-control station_search new_class"
              showSearch
              onSearch={onSearch}
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              // defaultValue="lucy"

              placeholder="Origin"
              style={{
                width: 460,
              }}
              onChange={(e) => setLiveStation(e)}
            >
              {newBookingsData?.stationsList?.map((item, i) => (
                <Option
                  key={i}
                  value={item?.StationCRSCode}
                  label={item?.StationName}
                >
                  <div>{item.StationName}</div>
                </Option>
              ))}
            </Select>
            {/* &nbsp; &nbsp; &nbsp; */}
            <button
              style={{ marginTop: "102px", marginBottom: "12px" }}
              className="button text"
              onClick={() => fetchStationInfo()}
            >
              GET
            </button>
          </div>
          <Table
            style={{ width: "45vw" }}
            columns={liveColumns}
            dataSource={stationInfo?.departures?.all}
          />
        </div>
      </div>
    </>
  );
};

export default GetTrainInfo;

// const App: React.FC = () => <Table columns={columns} dataSource={data} />;

// aimed_arrival_time: "13:45";
// aimed_departure_time: "13:45";
// aimed_pass_time: null;
// category: "OO";
// destination_name: "London Waterloo";
// mode: "train";
// operator: "SW";
// operator_name: "South Western Railway";
// origin_name: "Reading";
// platform: "2";
// service: "24672104";
// service_timetable: {
//   id: "https://transportapi.com/v3/uk/train/service_timet…a1b00e73&app_key=bd91d003f7678656a36759356a09d7ca";
// }
// source: "ATOC";
// train_uid: "L22638";
