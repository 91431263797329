import React, { useEffect, useState } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StatusMessages, { useMessages } from "./StatusMessages";
import { useNavigate } from "react-router";

const ApplePay = ({ amount, dispatchFn }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [messages, addMessage] = useMessages();
  const navigate = useNavigate();

  console.log(amount, "amount");
  useEffect(() => {
    if (!stripe || !elements) {
      return;
    }

    console.log(stripe, typeof Number(amount), elements, "stripe elements");

    const pr = stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Amount",
        amount: parseInt(amount),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then((result) => {
      if (result) {
        console.log(result, "result");
        setPaymentRequest(pr);
      }
    });

    pr.on("paymentmethod", async (e) => {
      const { error: backendError, clientSecret } = await fetch(
        "/create-payment-intent",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodType: "card",
            currency: "usd",
          }),
        }
      ).then((r) => r.json());

      if (backendError) {
        addMessage(backendError.message);
        return;
      }

      addMessage("Client secret returned");

      const { error: stripeError, paymentIntent } =
        await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: e.paymentMethod.id,
          },
          { handleActions: false }
        );

      if (stripeError) {
        console.log(stripeError);
        // Show error to your customer (e.g., insufficient funds)
        addMessage(stripeError.message);
        return;
      }
      dispatchFn();
      console.log("payment success");
      // navigate("/myBookings");

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.
    });
  }, [stripe, elements, addMessage]);

  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}

      <StatusMessages messages={messages} />
    </>
  );
};

export default ApplePay;
