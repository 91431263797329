import React from "react";
import ApplePay from "../components/payment.js/applePayment/ApplePay";
import Paypal from "../components/payment.js/paypal/Paypal";
import Gpay from "../components/payment.js/googlePay/Gpay";

const PaymentModule = ({ amount, currency, bookingType, dispatchFn }) => {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  console.log(amount, currency, isSafari, bookingType, "amount curr");
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        columnGap: "16px",
      }}
      className="payment-module-wrapper"
    >
      <Paypal amount={amount} dispatchFn={dispatchFn} currency={currency} />

      {isSafari && (
        <ApplePay amount={amount} dispatchFn={dispatchFn} currency={currency} />
      )}

      <Gpay
        dispatchFn={dispatchFn}
        amount={amount}
        currency={currency}
        bookingType={bookingType}
      />
    </div>
  );
};

export default PaymentModule;
