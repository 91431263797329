import React from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router";

const Paypal = ({ amount, currency = "USD", dispatchFn }) => {
  const [{ isPending }] = usePayPalScriptReducer();
  const navigate = useNavigate();
  return (
    <>
      {isPending ? <div className="spinner">Loading</div> : null}

      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                  currency_code: "USD",
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          // navigate("/myBookings");
          dispatchFn();
          return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);
          });
        }}
        style={{ layout: "vertical" }}
      />
    </>
  );
};

{
  /* <PayPalScriptProvider
options={{
  "client-id": "test",
  "data-client-token": "abc123xyz==",
}}
>
<BraintreePayPalButtons
  createOrder={(data, actions) => {
    return actions.braintree.createPayment({
      flow: "checkout",
      amount: "10.0",
      currency: "USD",
      intent: "capture",
    });
  }}
  onApprove={(data, actions) => {
    return actions.braintree.tokenizePayment(data).then((payload) => {
      // call server-side endpoint to finish the sale
    });
  }}
/> 
</PayPalScriptProvider>
*/
}

export default Paypal;
