import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Layout, Menu } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';
import images from "../../themes/appImage";

import { Collapse } from "antd";
import MobileSidebar from "../../common/mobilesidebar";
import HeaderMain from "../../common/header";
import { getMemoizedAuthenticationData } from "../../redux/selectors/authentication";
import { drawerAction } from "../../redux/actions/authentication";
import FooterMain from "../../common/footer";
import Icon1 from "../../common/icon";
import "../../css/trainticekt.css"
import { Link } from "react-router-dom";
import InfoCard from "../../common/InfoCard";
import { minWidth } from "@mui/system";
import ImgCard from "../../common/ImgCard";
import ButtonCard from "../../common/ButtonCard";
import TrainCard from "../../common/TrainCard";
import RailCards from "../../common/RailCards";
// import ButtonCard from "../../common/ButtonCard";


const AdvanceTrainTickets = (props) => {
  const [WrapperDisplay, setWrapperDisplay] = useState("none")
  console.log("welcome investors screen.");
  const dispatch = useDispatch();
  const authenticationData = useSelector(getMemoizedAuthenticationData);
  const { drawerState } = authenticationData;
  const handlewClick = () => {
    let action = drawerState ? false : true;
    dispatch(drawerAction(action, "drawerState"));
  };
  useEffect(() => {
    document.title = "The PassengerHub";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MobileSidebar isVisible={drawerState} handlewClick={handlewClick} />
      <HeaderMain handleClick={handlewClick} />
      <div className="mobileabout text-item">
        <div className="container-fluid spacert">
          <h3>Advance Train Tickets</h3>
        </div>
      </div>
      <div className="cheap-train-tickets ">
        <div className="container">

          <div className="row my-2">
            <ImgCard text="Prices from hundreds of train and coach companies" image={images.train} />
            <ImgCard text="Join millions of people who use us every day" image={images.Chip} />
            <ImgCard text="Travel to thousands of destinations in 45 countries" image={images.location} />
          </div>
         
          <div className="row p-5 text-left ">
           
            <p className="my-2"><strong>Did you know that you could save money by planning well ahead and booking an Advance train ticket? Plus, we'd recommend booking travel before you get to the station, as this will limit your time spent there. For safe, contactless travel, choose a digital ticket where possible.</strong></p>
            <p className="my-2"><strong>We'll fill you in on all the details about Advance tickets, including rules and restrictions, and show you how to find Advance tickets for your preferred train company and route. Check out all the info below, from basic advice on how to buy tickets to frequently asked questions.</strong></p>
          </div>
          <div className="row p-5  ">
           
            <h2 className="my-2 text-center w-100 "><strong className=" w-100">Book with confidence with Advance tickets</strong></h2>
            <p className="my-2 text-left"><strong>December 2022 update:</strong>You can currently request a fee-free exchange to your Advance ticket if you book with Trainline. This means if your plans change, you can make multiple changes fee-free, but <br /> <strong>must change the date/time by 18:00 the day before travel.</strong>Changes can be made after 18:00, up until the date/time of travel but a fee will be applied.</p>
          </div>
          
          
        </div>
        <div className="row container p-5  ">
           
            <h2 className="my-2 text-center w-100"><strong className="text-center w-100 ">Advance train tickets explained</strong></h2>
            <p className="my-2 text-center"><strong>Know exactly when and where you need to travel? Book your train tickets in advance on our app and secure your place on board your chosen service with an Advance ticket. Read on for more information.</strong></p>
          </div>
        <div className="card-section  py-4 my-2"  style={{background:'#fafafa'}}>
           
            <div className="container-fluid ">
              <div className="row px-5">

           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard boxShadow="no" CardBorder="no" headingfont="24px"  heading="What is an Advance train ticket?" textFont="15px" text={ <> Advance train tickets are fares that go<strong>on sale well before the day of travel</strong> . They're only available as Single tickets for A to B rail journeys and are non-refundable. They're also non-flexible. Perfect for those who like to plan their journeys well ahead of time, Advance tickets are the best way to be organised when it comes to travel. </>} /></div>

           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard boxShadow="no" CardBorder="no" headingfont="24px"  heading="Why book train tickets in advance?" textFont="15px" text={<><p className="my-2">Booking train tickets in advance on our app can help you save time at the station.  <strong>Digital tickets</strong>  offer you contactless travel as you scan them directly from your phone, so there’s no need to touch ticket machines either.</p> <p className="my-2">Although not all train services will let you reserve a seat, you'll stand a better chance of getting one when <strong>booking in advance.</strong> </p><p className="my-2">Advance train tickets <strong>may also be available for First Class travel</strong> , meaning you can enjoy all the perks of a higher standard of train travel at a good price.</p></>} /></div>
           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard boxShadow="no" CardBorder="no" headingfont="24px"  heading="Can I buy Advance Return tickets?" textFont="15px" text={ <p> No, Advance tickets are only sold as Single Tickets. However, you can buy an Advance Single train ticket for both directions, meaning you can <strong>mix and match Single tickets</strong>  for the cheapest possible return journey to your chosen destination.</p>} /></div>
           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard boxShadow="no" CardBorder="no" headingfont="24px"  heading="When do Advance train tickets become available?" textFont="15px" text={<p className="mb-2">Typically, train companies release their Advance tickets 12 weeks before the departure date, although some go on sale as far as 24 weeks in advance! Only a limited number of tickets will be available, so be quick to secure yours as the fare can rise as the date of travel gets nearer. <br />
           <br />You can also check the Advance train ticket release dates for certain UK train companies on our Ticket Alert page.</p> 
          } /></div>

           
              
              </div>

            </div>
          </div>
        <div className="row container p-5  ">
           
            <h2 className="my-2 text-center w-100"><strong className="text-center w-100 ">How to find cheap Advance train tickets</strong></h2>
            <p className="my-2 text-center"><strong>If you’re after cheap Advance tickets, check out our top tips below and see how much you could save on your train journey. Want to bag yourself the latest offers on UK train travel? Visit our train deals and discounts page to search for all the available deals in the UK.</strong></p>
          </div>
        <div className="card-section  py-4 my-2"  style={{background:'#fafafa'}}>
           
            <div className="container-fluid ">
              <div className="row px-5">

           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard img="https://www.thetrainline.com/cms/media/4235/off-peak-clock.png" boxShadow="no" CardBorder="no" headingfont="20px"  heading="Travel Off-Peak" textFont="15px" text={ <> <p className="my-2">When buying your Advance ticket, make sure you book an <strong>Off-Peak time</strong> . Tickets are usually cheaper during quieter times on weekdays and weekends than during Peak times. Use our</p><p className="my-2">Journey Planner at the top of the page to start your search and we’ll highlight the cheapest Off-Peak tickets for you. Learn more about Off-Peak train tickets.</p> </>} /></div>

           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard img="https://www.thetrainline.com/cms/media/5387/picto_etix_tick.png" boxShadow="no" CardBorder="no" headingfont="20px"  heading="Sign up for Ticket Alert" textFont="15px" text={<><p className="my-2">Sign up for our handy Advance Ticket Alert, which can help you plan your journeys further in advance and find tickets available at lower prices. Our clever tool will send you an email as soon as Advance tickets become available for your preferred route.</p><p className="my-2">Learn more about our Advance Train Ticket Alert tool.</p></>} /></div>
           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard img="https://www.thetrainline.com/cms/media/4398/calendar_pictogram.png" boxShadow="no" CardBorder="no" headingfont="20px"  heading="Look out for Advance tickets on the day" textFont="15px" text={ <p className="my-2">Occasionally, Advance tickets are still available on the same day of travel, and you can use our app to get your hands on these. Just bear in mind that we highlight the cheapest option for the day, so Advance tickets may not always be the best choice on some days. <br /><br /> Learn more about last-minute train tickets.</p>} /></div>
           <div className='col-md-6 col-sm-10 mx-auto my-2'>   <TrainCard img="https://www.thetrainline.com/cms/media/4406/railcards_pictogram.png" boxShadow="no" CardBorder="no" headingfont="20px"  heading="Get a Railcard" textFont="15px" text={<p className="mb-2">Even more music for your ears, you can get extra discounts on Advance train tickets if you've got an applicable Railcard. Children, those aged 16-17 and 16-25, Disabled Persons and Seniors can all use their Railcards to get 1/3 off most Standard and First Class tickets. The Two Together and Family & Friends Railcards are also applicable on certain routes, although not always for First Class travel. <br />
           <br />Learn more about Railcards.</p> 
          } /></div>

           
              
              </div>

            </div>
          </div>

          <div className="row container p-5  ">
           
            <h2 className="my-2 text-center w-100"><strong className="text-center w-100 ">Frequently Asked Questions</strong></h2>
            <p className="my-2 text-center"><strong>Want to learn more about Advance tickets? Read our answers to some of our customers’ frequently asked questions on this topic.</strong></p>
          </div>
          <div className="container">
            <div className="row">
              <h4 className="my-3"><strong>Is it cheaper to buy a train ticket in advance?</strong></h4>
              <p className="my-2">Yes. Advance train tickets are usually the cheapest way to travel by train, and generally go on sale 12 weeks before your date of travel. You’ll need to travel on the train you're booked on, but you’ll get a cheaper price by foregoing flexible travel times</p>
              <h4 className="my-3"><strong>Where can I use an Advance ticket?</strong></h4>
              <p className="my-2">Where can I use an Advance ticket? <br /><br />Trainline top tip: Avoid breaking your journey with Advance tickets to steer clear of additional charges!</p>
              <h4 className="my-3"><strong>Can I get an earlier train with an Advance ticket?</strong></h4>
              <p className="my-2">Advance train tickets aren't flexible, so you must catch the train specified on your ticket. If you catch an earlier train, you may have to pay additional fees, so please check with ticket staff at the station.</p>
              <h4 className="my-3"><strong>Can I get an Advance train ticket refund?</strong></h4>
              <p className="my-2">Advance Single tickets can't be refunded, but they can be exchanged for a new ticket on a different service on either the same day or another date, subject to a fee. Please note that you won't be able to change your route.</p>
            </div>
          </div>
          
     
      </div>
      <FooterMain />
      <Icon1 handleClick={handlewClick} />
    </div>
  );
};
export default AdvanceTrainTickets;
