import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.css";
import "../src/css/home.css";
import "../src/css/responsive.css";
import "../src/css/bootstrap.css";
import "../src/css/responsivehome.css";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const stripePromise = loadStripe(
  "pk_test_51Jhv2sGfOTJnV5SoIwp0EZ3zXD2CxvH6NkafIGo172OYUHjpO75PrfZjZJfUe5yyRVlhJnNGasKl9DnBwnJK3YTB00UrPHUgt6"
);

ReactDOM.render(
  <React.StrictMode>
    <Elements stripe={stripePromise}>
      <PayPalScriptProvider
        options={{
          currency: "USD",
          "client-id":
            "AW0rLWDPY-qE1QxilkQo9Gtmzq5mpr0ettnqw7i1m0eqOsTaXA6jwh9ntI2p2JD0i1rA01N07GTmZTdD",
        }}
      >
        <Provider store={store}>
          <App />
        </Provider>
      </PayPalScriptProvider>
    </Elements>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
