
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";

import { Collapse, Select, DatePicker, Space, Button } from "antd";
import { Radio, InputNumber, TimePicker } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getMemoizedAuthenticationData } from "../../redux/selectors/authentication";
import { drawerAction } from "../../redux/actions/authentication";
import MobileSidebar from "../../common/mobilesidebar";
import HeaderMain from "../../common/header";
import FooterMain from "../../common/footer";
import { appConstants } from "../../themes/appConstant";
import { CookieSharp } from "@mui/icons-material";
const { Option } = Select;

const OurPlan = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(1);

  const authenticationData = useSelector(getMemoizedAuthenticationData);
  const { drawerState } = authenticationData;
  const { Panel } = Collapse;

  const handlewClick = () => {
    let action = drawerState ? false : true;
    dispatch(drawerAction(action, "drawerState"));
  };
  useEffect(() => {
    document.title = "The PassengerHub";
    window.scrollTo(0, 0);
  }, []);
  const PanelArray3 = [
    {
      id: "1",
      Header: "Europe’s leading independent rail platform",
      Para: <>
      <p className="mb-2">We enable millions of travellers to seamlessly search, book and manage their journeys through our highly rated Trainline website, mobile app and B2B partner channels.</p>
      <ol className="my-2" style={{listStyleType:'disc'}}>
        <li className="my-1">4.9/5 star rated app</li>
        <li className="my-1">44 million cumulative Trainline mobile app downloads</li>
        <li className="my-1">c.30 million MAUs across the UK and Europe</li>
        <li className="my-1">c.78 million platform visits each month</li>
      </ol>
      </>,
    },
    {
      id: "2",
      Header:
        "Market-leading customer experience",
      Para:  <>
      <p className="mb-2">Our innovation focuses on creating a simple, consistent, friction-free experience for booking and managing travel. We bring together all carriers into one app while providing smart, real-time travel information and self-serve capabilities like journey changes and refunds. </p>
      <ol className="my-2" style={{listStyleType:'disc'}}>
        <li className="my-1">Simple, intuitive user interface with all carriers, fares and railcards in one place</li>
        <li className="my-1">Seamless, friction-free booking experience</li>
        <li className="my-1">Digital tickets and railcards, smart personalisation, real time travel info </li>
        <li className="my-1">Digital tickets and railcards, smart personalisation, real time travel info </li>
        <li className="my-1">Self-service change of journey, automated refund capability</li>
      
      </ol>
      </>,
    },
    {
      id: "3",
      Header: "Digital marketing leadership",
      Para:  <>
      <p className="mb-2">We have developed a highly scalable, marketing playbook in the UK, which we are deploying across our core markets in Europe. This helps drive customer acquisition at scale while maintaining a low cost per new customer acquired (“CPA”)</p>
      <ol className="my-2" style={{listStyleType:'disc'}}>
        <li className="my-1">c.80% new customer acquisition through free channels in the UK (e.g. SEO)</li>
        <li className="my-1">Sophisticated CRM strategies to drive engagement and frequency</li>
        <li className="my-1">Shifting customers to mobile app: 84% app share of transactions in the UK </li>
        
      
      </ol>
      </>,
    },
    {
      id: "4",
      Header: "Platform One – single global tech platform",
      Para: <>
      <p className="mb-2">As a platform business, we offer our innovative retailing experience directly to customers through our Trainline-branded businesses, while also giving carrier partners and other travel businesses access to our retail solutions to offer to their customers. </p>
      <ol className="my-2" style={{listStyleType:'disc'}}>
        <li className="my-1">270+ rail and coach companies with deep integrations across 45 countries</li>
        <li className="my-1">c400 engineers, data and tech specialists </li>
        <li className="my-1">End-to-end digital retailing and ticketing solutions for rail carriers at a lower cost to serve </li>
        <li className="my-1">Provides travel sellers access to our rail content through one connection - our global API</li>
        
      
      </ol>
      </>,
    },
    {
      id: "5",
      Header: "A strong and experienced management team",
      Para:<>
      <p className="mb-2">At Trainline, we recognise the importance of building and sustaining a strong, experienced management team. Click on the link below for more information about our management team.</p>
      <Button  style={{background:"#7cd2cd",color:'white'}}>Meet the team</Button></> },
    
  ];
  return (
    <div>
      <MobileSidebar isVisible={drawerState} handlewClick={handlewClick} />
      <HeaderMain handleClick={handlewClick} />
      <div className="mobileabout text-item">
        <div className="container-fluid spacert">
          <h3>Our Plan</h3>
        </div>
      </div>
      <div className="press">
        <div className="container">
         <h4 className="my-4">Trainline has competitive strengths and structural tailwinds that position the business for significant long-term growth.</h4>
        </div>
      </div>
      <div className="section">
        <div className="container py-3">
         
          <div className="faq-accordian">
            <h2 className="line" style={{color:'#00bb9c'}}>Competitive strengths </h2>
             
            <div className="accordion">
              <Collapse accordion>
              {PanelArray3.map((val, idx) => {
              return (
                <>
                  <Panel header={val.Header} key={idx}>
                    <p className="p-2">{val.Para}</p>
                  </Panel>
                </>
              );
            })}
               
              </Collapse>
            </div>
          </div>
        </div>
        <div className="container-fluid p-2 my-4">
    <div className="row px-md-5">
    <div className="col-md-6 px-3">
        <h3 className="mb-3 line"><strong>Rail market shifting to online and mobile ticketing</strong></h3>
        <ol className="my-3" style={{listStyleType:'disc'}}>
          <li className="my-2"><strong>Customers are increasingly booking tickets online, now 50% of all ticket sales in UK</strong></li>
          <li className="my-2"><strong>This has largely been driven by an increasing use of barcode read digital tickets (or etickets), doubling in last two years to 40% of ticket sales</strong></li>
          <li className="my-2"><strong>Trainline has championed etickets for several years as a core part of our mobile app proposition</strong></li>
          <li className="my-2"><strong>Significant headroom for further growth - eticket-enabled journeys expected to surpass 90% of all journeys in the UK by 2023</strong></li>
        </ol>
    </div>
    <div className="col-md-6 d-flex align-items-center justify-content-center">
        <img src="https://trn-13455-s3.s3.eu-west-2.amazonaws.com/media/1416/5366/3640/innovation-1-1440x1080.jpg" style={{width:'95%',height:'auto'}} alt="" />
    </div>
    </div>
</div>
<div className="container-fluid p-2 my-4">
    <div className="row px-md-5">
    <div className="col-md-6 d-flex align-items-center justify-content-center">
        <img src="https://trn-13455-s3.s3.eu-west-2.amazonaws.com/media/6216/4572/8235/about-platform-915x686.jpg" style={{width:'95%',height:'auto'}} alt="" />
    </div>
    <div className="col-md-6 px-3">
        <h3 className="mb-3 line"><strong>European domestic rail markets liberalising at pace, creating need for an aggregator</strong></h3>
        <ol className="my-3" style={{listStyleType:'disc'}}>
          <li className="my-2"><strong>The EU’s Fourth Railway Package (December 2020) opened domestic rail markets to competition</strong></li>
          <li className="my-2"><strong>As a result, carrier competition is emerging across France, Spain, and Italy, with the major carriers there entering each other’s markets, e.g.</strong></li>
          <li className="my-2"><strong>SNCF’s Ouigo brand entered Spain in May 2021</strong></li>
          <li className="my-2"><strong>Italy’s Trenitalia entered French market in late 2021</strong></li>
          <li className="my-2"><strong>Trenitalia’s Iryo band (JV) due to enter Spanish market in 2022</strong></li>
          <ol className="my-3" style={{listStyleType:'circle'}}> 
          <li className="my-2"><strong>Trainline positioning itself as the rail retailer in these core European markets, aggregating all the carriers, fares, and journey options in one place</strong></li>
          <li className="my-2"><strong>This helps customers navigate the increased complexity to find the right ticket, while bringing incremental customer demand to new entrant carriers</strong></li>
         
          
        </ol>
        </ol>
        
    </div>
   
    </div>
</div>
        <div className="container-fluid p-2 my-4">
    <div className="row px-md-5">
    <div className="col-md-6 px-3">
        <h3 className="mb-3 line"><strong>Governments investing to shift people on to trains as a greener mode of transport</strong></h3>
        <ol className="my-3" style={{listStyleType:'disc'}}>
          <li className="my-2"><strong>Trainline’s purpose is to empower greener travel choices, encouraging people to switch to rail from car and air travel </strong></li>
          <li className="my-2"><strong>Rail generates less than 1/20 of the CO2 emissions of air travel and less than 1/7th of the CO2 emissions compared with car travel</strong></li>
          <li className="my-2"><strong>UK and European governments continue to encourage a modal shift to rail, and increasing their investment to meet net-zero emissions goals:</strong></li>
          <ol className="my-2" style={{listStyleType:'circle'}}>
            <li className="my-1">UK and European governments continue to encourage a modal shift to rail, and increasing their investment to meet net-zero emissions goals:</li>
            <li className="my-1">EU target to double passengers on high-speed rail by 2030 and triple passengers by 2050, with investments planned over the next ten years totalling £176 billion</li>
          </ol>
         
        </ol>
       
    </div>
    <div className="col-md-6 d-flex align-items-center justify-content-center">
        <img src="https://trn-13455-s3.s3.eu-west-2.amazonaws.com/media/1416/5366/3640/innovation-1-1440x1080.jpg" style={{width:'95%',height:'auto'}} alt="" />
    </div>
    </div>
</div>

      </div>
      <FooterMain />
    </div>
  );
};
export default OurPlan;
