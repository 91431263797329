import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Spin } from "antd";
import images from "../../../themes/appImage";
// import tableLoading from '../common/tableloading'
import { BsDownload } from "react-icons/bs";

import { toast } from "react-nextjs-toast";
import moment from "moment";
import { Table, Breadcrumb, Modal, Pagination } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { appConstants } from "../../../themes/appConstant";
import { getMemoizedMyBookingsData } from "../../../redux/selectors/myBookings";

import {
  getTrainBookingsInitiate,
  updateBookingsState,
  cancelBookingInitiate,
} from "../../../redux/actions/myBookings";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { jsPDF } from "jspdf";

const { Header, Content, Footer, Sider } = Layout;

const TrainBookings = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toPrintRef = useRef();

  const [pageIndex, setPageIndex] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState("");
  const [modalTextArea, setModalTextArea] = useState("");
  const [modalTextAreaError, setModalTextAreaError] = useState("");

  const trainBookingsData = useSelector(getMemoizedMyBookingsData);
  const {
    trainBookingsLoader,
    trainBookingsSuccess,
    trainBookingsList,
    trainBookingsPagination,
    offsetValueActive,
    offsetValueFuture,
    offsetValuePast,
    searchValueActive,
    searchValueFuture,
    searchValuePast,
    screenTabTrain,
    cancelBookingLoader,
    cancelBookingSuccess,
    cancelBookingDetail,
  } = trainBookingsData;

  const handlePrint = useReactToPrint({
    content: () => toPrintRef.current,
    documentTitle: `Passenger-hub-ticket.pdf`,
  });

  useEffect(() => {
    document.title = "The PassengerHub";
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    if (cancelBookingSuccess) {
      handleModalClose();
      dispatch(updateBookingsState(false, "cancelBookingSuccess"));
    }
  }, [cancelBookingSuccess]);

  // printing componnet
  const toPrint = (index) =>
    trainBookingsList[pageIndex]?.ticketId !== "Season" ? (
      <>
        <div id="toPrint" ref={toPrintRef}>
          <div
            id="printRecipt"
            style={{
              margin: "6rem auto 2rem",
              border: "1px solid lightgray",
              borderRadius: "10px",
              overflow: "hidden",
              maxWidth: "700px",
            }}
          >
            <h2
              style={{
                fontWeight: 700,
                fontSize: "35px",
                letterSpacing: ".5px",
                marginBottom: "0px",
                padding: "20px",
                background:
                  "linear-gradient( 90deg, #84e0ab 5%, #7bd0d3 51.25%, #84e0ab 93.75%3)",
                textAlign: "center",
              }}
            >
              Order details
            </h2>
            <div className="order-content">
              <h4>Order Number : {trainBookingsList[pageIndex]?.ticketId}</h4>
              <h5>Order Summary</h5>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="o-c-data"
              >
                <div className="o-c-d-title">Order Date :</div>
                <div className="o-c-value">
                  {/* {moment(
                    tubeBookingsData?.tubeBookingsList[pageIndex]?.createdAt
                  ).format("YYYY-MM-DD")} */}
                </div>
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="o-c-data"
              >
                <div className="o-c-d-title">Ticket ID :</div>
                <div className="o-c-value">
                  {trainBookingsList[pageIndex]?.ticketId}
                </div>
              </div>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="o-c-data"
              >
                <div className="o-c-d-title">Payment Card :</div>
                <div className="o-c-value">Visa</div>
              </div>
              <h5>Billing Details</h5>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="o-c-data"
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                  className="o-c-d-title"
                >
                  Name and Address :
                </div>
                <div className="o-c-value">
                  {trainBookingsList[pageIndex]?.user?.fullName}
                  <br />
                  {/* {userDetails?.address} */}
                </div>
              </div>
              <h5>Items in this order</h5>
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="o-c-i-data"
              >
                <div className="o-c-i-d-title">Item</div>
                <div className="o-c-i-value">Price</div>
              </div>
              <hr />
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                className="o-c-i-data"
              >
                <div className="o-c-i-d-title">
                  {trainBookingsList[pageIndex]?.destination} Travelcard{" "}
                  {trainBookingsList[pageIndex]?.source}
                  <br />
                  From{" "}
                  {moment(trainBookingsList[pageIndex]?.createdAt).format(
                    "YYYY-MM-DD"
                  )}{" "}
                  to{" "}
                  {trainBookingsList[pageIndex]?.duration &&
                    moment(trainBookingsList[pageIndex]?.createdAt)
                      .add(
                        trainBookingsList[pageIndex]?.duration,
                        trainBookingsList[pageIndex]?.duration == "Day"
                          ? "d"
                          : "M"
                      )
                      .format("YYYY-MM-DD")}
                  <br />
                  Collected at: Stockwell
                  <br />
                  Status
                  {/* {tubeBookingsData?.tubeBookingsList[pageIndex]?.s} */}
                </div>
                <div className="o-c-i-value">£41.20</div>
              </div>
              <div className="o-c-i-data mt-1">
                <div className="o-c-i-d-title">Total Charged</div>
                <div className="o-c-i-price">£41.20</div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <div class="ord-main" ref={toPrintRef}>
        <div class="ord-main-wrap">
          <h3>
            Confirmation -{" "}
            <span>
              Order Reference {trainBookingsList[pageIndex]?.ticketId}
            </span>
          </h3>
          <div class="ord-data-box">
            <h4>Order Summary</h4>
            <div class="ord-data-flex">
              <div class="ord-data-flex-left">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Order Reference:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.ticketId}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Order Status:</div>
                  <div class="ord-detail-value">
                    Photocard image being Approved
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Booker:</div>
                  <div class="ord-detail-value">
                    {" "}
                    {trainBookingsList[pageIndex]?.user?.fullName}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Booking Agency:</div>
                  <div class="ord-detail-value">The Passenger Hub</div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Account:</div>
                  <div class="ord-detail-value">
                    {" "}
                    XXXX {trainBookingsList[pageIndex]?.user?.card?.cardNumber}
                  </div>
                </div>
              </div>
              <div class="ord-data-flex-right">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Transaction:</div>
                  <div class="ord-detail-value">New</div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Transaction Id:</div>
                  <div class="ord-detail-value">00010269</div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Order Date:</div>
                  <div class="ord-detail-value">
                    {moment(trainBookingsList[pageIndex]?.createdAt).format(
                      "lll"
                    )}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Delivery Method:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.deliveryMethod}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ord-data-box">
            <h4>
              Order Item {trainBookingsList[pageIndex]?.source} to{" "}
              {trainBookingsList[pageIndex]?.destination}
            </h4>
            <div class="ord-data-flex">
              <div class="ord-data-flex-left">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Start date:</div>
                  <div class="ord-detail-value">
                    {moment(trainBookingsList[pageIndex]?.startDate).format(
                      "Do MMM, YYYY"
                    )}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">End Date:</div>
                  <div class="ord-detail-value">
                    {moment(trainBookingsList[pageIndex]?.endDate).format(
                      "Do MMM, YYYY"
                    )}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Duration:</div>
                  <div class="ord-detail-value">{`${trainBookingsList[pageIndex]?.duration?.m} Months ${trainBookingsList[pageIndex]?.duration?.d} Days`}</div>
                </div>
              </div>
              <div class="ord-data-flex-right">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Route:</div>
                  <div class="ord-detail-value">
                    Travel is allowed via any permitted route.
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Class:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.class}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ord-data-box">
            <h4>Traveller Details</h4>
            <div class="ord-data-flex">
              <div class="ord-data-flex-left">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Name:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.user?.fullName}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Photocard Number:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.cardNumber}
                  </div>
                </div>
              </div>
              <div class="ord-data-flex-right">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Type:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.passengerType}
                  </div>
                </div>
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Fare:</div>
                  <div class="ord-detail-value">
                    £{trainBookingsList[pageIndex]?.price}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ord-data-box">
            <h4>delivery Address</h4>
            <div class="ord-data-flex">
              <div class="ord-data-flex-left">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Contact:</div>
                  <div class="ord-detail-value">
                    {trainBookingsList[pageIndex]?.user?.fullName}
                  </div>
                </div>
              </div>
              <div class="ord-data-flex-right">
                <div class="ord-detail-flex">
                  <div class="ord-detail-title">Address:</div>
                  <div class="ord-detail-value">
                    {" "}
                    {trainBookingsList[pageIndex]?.user?.address}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ord-fee-box">
            <div class="ord-fee-flex">
              <div class="ord-fee-data">
                <div class="ord-fee-title">Oredr Item Costs:</div>
                <div class="ord-fee-value">
                  £
                  {trainBookingsList[pageIndex]?.price -
                    trainBookingsList[pageIndex]?.deliveryFee}
                </div>
              </div>
              <div class="ord-fee-data">
                <div class="ord-fee-title">Transaction Fee:</div>
                <div class="ord-fee-value">£00.00</div>
              </div>
              <div class="ord-fee-data">
                <div class="ord-fee-title">Delivery Fee:</div>
                <div class="ord-fee-value">
                  £{trainBookingsList[pageIndex]?.deliveryFee}
                </div>
              </div>
              <div class="ord-fee-data total-fee">
                <div class="ord-fee-title">Total:</div>
                <div class="ord-fee-value">
                  £{trainBookingsList[pageIndex]?.price}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  const onPrint = (index) => {
    setPageIndex(index);
    console.log(index, trainBookingsList, "tbk");
    toast.notify(`Printing...`);
    setTimeout(() => {
      handlePrint();
      setPageIndex(null);
    }, 2000);
    return () => clearTimeout(handlePrint);
  };

  const offsetData = () => {
    switch (screenTabTrain) {
      case 1:
        return {
          offset: offsetValueActive,
          fieldName: "offsetValueActive",
        };

      case 2:
        return {
          offset: offsetValueFuture,
          fieldName: "offsetValueFuture",
        };

      case 3:
        return {
          offset: offsetValuePast,
          fieldName: "offsetValuePast",
        };

      default:
        break;
    }
  };

  const searchData = () => {
    switch (screenTabTrain) {
      case 1:
        return {
          searchValue: searchValueActive,
          fieldName: "searchValueActive",
        };

      case 2:
        return {
          searchValue: searchValueFuture,
          fieldName: "searchValueFuture",
        };

      case 3:
        return {
          searchValue: searchValuePast,
          fieldName: "searchValuePast",
        };

      default:
        break;
    }
  };

  useLayoutEffect(() => {
    const data = {
      bookingStatus: "trainBookings",
      status:
        screenTabTrain === 1
          ? "activeBookings"
          : screenTabTrain === 2
          ? "futureBookings"
          : "pastBookings",
      sort: null,
      offset: searchData().searchValue ? 0 : offsetData().offset,
      limit: 10,
      order: null,
      search: searchData().searchValue,
    };
    dispatch(getTrainBookingsInitiate(data, navigate));
  }, [screenTabTrain]);

  const handleTableChange = async (pagination, filters, sorter) => {
    let data = {
      bookingStatus: "trainBookings",
      status:
        screenTabTrain === 1
          ? "activeBookings"
          : screenTabTrain === 2
          ? "futureBookings"
          : "pastBookings",
      sort: null,
      offset: searchData().searchValue ? 0 : offsetData().offset,
      limit: 10,
      order: null,
      search: searchData().searchValue,
    };
    if (sorter.order == "ascend") {
      data.order = 1;
      data.sort = sorter.columnKey;
      dispatch(getTrainBookingsInitiate(data, navigate));
    } else if (sorter.order === "descend") {
      data.order = -1;
      data.sort = sorter.columnKey;
      dispatch(getTrainBookingsInitiate(data, navigate));
    } else {
      dispatch(getTrainBookingsInitiate(data, navigate));
    }
  };

  console.log("====================================");
  console.log(
    trainBookingsData,
    trainBookingsData?.trainBookingsList?.length,
    "tbd length"
  );
  console.log("====================================");

  const columns = [
    // {
    //   title: "TPH Number",
    //   dataIndex: "srno",
    //   key: "srno",
    //   sorterDirections: ["descend", "ascend"],
    //   render: (value, item, index) => index + 1 + offsetData().offset,
    // },
    {
      title: "TPH Number",
      dataIndex: "ticketId",
      key: "ticketId",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return (
          <div
            onClick={() => {
              navigate("/bookingdetails", {
                state: { useDetails: item },
              });
            }}
          >
            <a href="/bookingdetails">
              {item?.ticketId?.toString().padStart(7, "0")}
            </a>
          </div>
          // <Link to={`/orderManager/${item.ticketId}`}>
          //   {`${trainBookingsData?.trainBookingsList?.length
          //     .toString()
          //     .padStart(7, "0")}`}
          // </Link>
        );
      },
    },
    // {
    //   title: "Email Address",
    //   dataIndex: "user",
    //   key: "email",
    //   sorter: true,
    //   sorterDirections: ["descend", "ascend"],
    //   render: (index, item) => {
    //     return <div>{item?.user?.email ? item?.user?.email : "N/A"}</div>;
    //   },
    // },
    {
      title: "Starting Station",
      dataIndex: "source",
      key: "source",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.source ? item.source : "N/A"}</div>;
      },
    },
    {
      title: "Ending Station",
      dataIndex: "destination",
      key: "destination",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.destination ? item.destination : "N/A"}</div>;
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return (
          <div>
            {typeof item.duration == "string"
              ? item.duration
              : item.duration.m > 0
              ? item.duration.d > 0
                ? item.duration.m + " Months" + item.duration.d + " Days"
                : item.duration.m + " Months"
              : item.duration.d + " Days"}
          </div>
        );
      },
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return (
          <div>
            {item.startDate
              ? moment(item.startDate).format("DD MMM YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    // {
    //   title: "Booking Status",
    //   dataIndex: "bookingstatus",
    //   key: "bookingstatus",
    //   sorter: true,
    //   sorterDirections: ["descend", "ascend"],
    //   render: (index, item) => {
    //     return <div>{item.isCancel ? "Cancelled" : "Done"}</div>;
    //   },
    // },
    {
      title: "Billing",
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record, index) => {
        return (
          <div
            className="billViewAndDownload"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div>
              <button
                type="submit"
                onClick={() =>
                  navigate("/bookingdetails", { state: { useDetails: record } })
                }
              >
                <img alt="" src={images.view} />
              </button>
            </div>
            <div style={{ opacity: 0, width: 0, height: 0 }}>
              {toPrint(index)}
            </div>
            <div style={{ paddingLeft: "15" }}>
              <BsDownload
                style={{ cursor: "pointer" }}
                onClick={() => onPrint(index)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const columnsfuture = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorterDirections: ["descend", "ascend"],
      render: (value, item, index) => index + 1 + offsetData().offset,
    },
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item?.ticketId?.toString().padStart(7, "0")}</div>;
      },
    },
    {
      title: "Email Address",
      dataIndex: "user",
      key: "email",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item?.user?.email ? item?.user?.email : "N/A"}</div>;
      },
    },
    {
      title: "Source Station Name",
      dataIndex: "source",
      key: "source",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.source ? item.source : "N/A"}</div>;
      },
    },
    {
      title: "Destination Station Name",
      dataIndex: "destination",
      key: "destination",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.destination ? item.destination : "N/A"}</div>;
      },
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return (
          <div>
            {item.startDate
              ? moment(item.startDate).format("DD MMM YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Booking Status",
      dataIndex: "bookingstatus",
      key: "bookingstatus",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.isCancel ? "Cancelled" : "Done"}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record) => {
        return (
          <div>
            <button
              type="submit"
              disabled={record.isCancel}
              onClick={() => handleModalOpen(record._id)}
            >
              <img src={images.cancel} />
            </button>
            <button
              type="submit"
              onClick={() =>
                navigate("/bookingdetails", { state: { useDetails: record } })
              }
            >
              {" "}
              <img src={images.view} />
            </button>
          </div>
        );
      },
    },
  ];

  const columnspast = [
    // {
    //   title: "Sr. No.1",
    //   dataIndex: "srno",
    //   key: "srno",
    //   sorterDirections: ["descend", "ascend"],
    //   render: (value, item, index) => index + 1 + offsetData().offset,
    // },
    {
      title: "TPH Number",
      dataIndex: "ticketId",
      key: "ticketId",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/bookingdetails", {
                state: { useDetails: item },
              });
            }}
          >
            {item?.ticketId?.toString().padStart(7, "0")}
          </div>
        );
      },
    },
    // {
    //   title: "Email Address",
    //   dataIndex: "user",
    //   key: "email",
    //   sorter: true,
    //   sorterDirections: ["descend", "ascend"],
    //   render: (index, item) => {
    //     return <div>{item?.user?.email ? item?.user?.email : "N/A"}</div>;
    //   },
    // },
    {
      title: "Starting Station",
      dataIndex: "source",
      key: "source",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.source ? item.source : "N/A"}</div>;
      },
    },
    {
      title: "Ending Station",
      dataIndex: "destination",
      key: "destination",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return <div>{item.destination ? item.destination : "N/A"}</div>;
      },
    },
    // {
    //   title: "Ticket Price",
    //   dataIndex: "price",
    //   key: "price",
    //   sorter: true,
    //   sorterDirections: ["descend", "ascend"],
    //   render: (index, item) => {
    //     return <div>{item.price ? `£${item.price}` : "N/A"}</div>;
    //   },
    // },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      sorter: true,
      sorterDirections: ["descend", "ascend"],
      render: (index, item) => {
        return (
          <div>
            {item.startDate
              ? moment(item.startDate).format("DD MMM YYYY")
              : "N/A"}
          </div>
        );
      },
    },
    // {
    //   title: "Booking Status",
    //   dataIndex: "bookingstatus",
    //   key: "bookingstatus",
    //   sorter: true,
    //   sorterDirections: ["descend", "ascend"],
    //   render: (index, item) => {
    //     return <div>{item.isCancel ? "Cancelled" : "Done"}</div>;
    //   },
    // },
    {
      title: "Billing",
      dataIndex: "Posted",
      key: "Posted",
      render: (text, record, index) => {
        return (
          <div
            className="billViewAndDownload"
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <div>
              <button
                type="submit"
                onClick={() =>
                  navigate("/bookingdetails", { state: { useDetails: record } })
                }
              >
                <img alt="" src={images.view} />
              </button>
            </div>
            <div style={{ opacity: 0, width: 0, height: 0 }}>
              {toPrint(index)}
            </div>
            <div style={{ paddingLeft: "15" }}>
              <BsDownload
                style={{ cursor: "pointer" }}
                onClick={() => onPrint(index)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleInputChange = (setValue, value, type, length) => {
    let data = value;
    if (value.target.value[0] === " ") {
      data.target.value = data.target.value.trim();
      return setValue(data.target.value.trimLeft());
    }
    if (data.target.value) {
      console.log("if");
      setModalTextAreaError("");
    } else {
      setModalTextAreaError("Please enter reason.");
    }
    return setValue(value.target.value.trimLeft());
  };

  const handleModalOpen = (data) => {
    setModalData(data);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setModalData("");
    setModalTextArea("");
    setModalTextAreaError("");
  };

  const handleModalSubmit = () => {
    if (!modalTextArea && !modalTextAreaError) {
      setModalTextAreaError("Please enter reason.");
      console.log("yes");
    } else if (modalTextArea && !modalTextAreaError) {
      console.log("ready..........");
      const data = {
        bookingId: modalData,
        reason: modalTextArea,
      };
      dispatch(cancelBookingInitiate(data, navigate));
    }
  };

  //// Share modal view
  const _modalView = () => {
    return (
      <Modal
        title="Cancel Booking Reason"
        centered
        className="sharemodal titles"
        width={536}
        visible={isModalVisible}
        footer={null}
        onCancel={() => handleModalClose()}
      >
        <div className="text_line_view">
          <h2>Reason</h2>
          <div className="form-group">
            <textarea
              className="form-control"
              placeholder="Reason"
              name="modalTextArea"
              value={modalTextArea}
              onKeyDown={(e) => {
                if (e.key === " " && e.target.value.length < 1) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => handleInputChange(setModalTextArea, e)}
            />
            {modalTextAreaError && (
              <div style={{ color: "red" }}>{modalTextAreaError}</div>
            )}
          </div>
          <div className="text-center">
            <button
              disabled={cancelBookingLoader}
              type="submit"
              className="button text"
              onClick={() => handleModalSubmit()}
            >
              {!cancelBookingLoader ? "Submit" : <Spin />}
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <div>
      <div className="table-wrapper">
        <div className="d-flex justify-content-center flex-wrap align-items-center">
          <div className="d-flex buttons">
            <button
              className={
                screenTabTrain === 1 ? "button text active" : "button text"
              }
              onClick={() => {
                dispatch(updateBookingsState(1, "screenTabTrain"));
              }}
            >
              {appConstants.activebooking}
            </button>
            {/* <button
              className={screenTabTrain === 2 ? 'button text active' : 'button text'}
              onClick={() => {
                dispatch(updateBookingsState(2, 'screenTabTrain'))
              }}
            >
              {appConstants.futurebookings}
            </button> */}
            <button
              className={
                screenTabTrain === 3 ? "button text active" : "button text"
              }
              onClick={() => {
                dispatch(updateBookingsState(3, "screenTabTrain"));
              }}
            >
              {appConstants.pastbookings}
            </button>
          </div>
          {/* <div>
            <div className="">
              {searchInput}
            </div>
          </div> */}
        </div>

        <div>
          <Table
            dataSource={trainBookingsList}
            columns={
              screenTabTrain === 1
                ? columns
                : screenTabTrain === 2
                ? columnsfuture
                : columnspast
            }
            showSorterTooltip={false}
            loading={trainBookingsLoader}
            onChange={handleTableChange}
            pagination={false}
          />
          <Pagination
            className="pagination"
            total={trainBookingsPagination?.totalCount}
            onChange={(page) => {
              let data = (page - 1) * 10;
              const dta = {
                bookingStatus: "trainBookings",
                status:
                  screenTabTrain === 1
                    ? "activeBookings"
                    : screenTabTrain === 2
                    ? "futureBookings"
                    : "pastBookings",
                sort: null,
                offset: data,
                limit: 10,
                order: null,
                search: "",
              };
              dispatch(getTrainBookingsInitiate(dta, navigate));
              dispatch(updateBookingsState(data, offsetData().fieldName));
            }}
            current={trainBookingsPagination?.currentPage}
            showSizeChanger={false}
          />
        </div>
      </div>

      {_modalView()}
    </div>
  );
};
export default TrainBookings;
