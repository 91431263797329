import React, { useState, useEffect } from "react";
import images from "../../../themes/appImage";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

import { getMemoizedAuthenticationData } from "../../../redux/selectors/authentication";
import { drawerAction } from "../../../redux/actions/authentication";
import MobileSidebar from "../../../common/mobilesidebar";
import HeaderMain from "../../../common/header";
import FooterMain from "../../../common/footer";
const { Option } = Select;

const PaymentShipping = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [value, setValue] = React.useState(1);
  const [cardvalue, setCardValue] = useState(1);

  //   const handleCardChange = () =>{
  //     setCardValue(cardvalue)
  //   }

  const authenticationData = useSelector(getMemoizedAuthenticationData);
  const { drawerState } = authenticationData;

  const handlewClick = () => {
    let action = drawerState ? false : true;
    dispatch(drawerAction(action, "drawerState"));
  };
  useEffect(() => {
    document.title = "The PassengerHub";
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MobileSidebar isVisible={drawerState} handlewClick={handlewClick} />
      <HeaderMain handleClick={handlewClick} />
      <div className="mobileabout text-item">
        <div className="container-fluid spacert">
          <h3>Booking</h3>
        </div>
      </div>
      <div className="press">
        <div className="container-fluid">
          <h2 className="line">Booking</h2>
          {/* <div className="text-line" style={{ paddingBottom: '20px' }}>
                        <img src={images.border1} style={{ width: '400px' }} />
                    </div> */}
          <div className="search-result">
            <div className="row">
              <div className="col-sm-6">
                <div className="text_wrapper_w payments pay_div crds-new">
                  <div className="text_top d-flex">
                    <div className="d-flex align-center bookert">
                      <img src={images.shiptruck} />
                      <p className="booker">Payment Methods</p>
                    </div>
                  </div>

                  <div className="first d-flex cards_text ">
                    {cardvalue == "1" ? (
                      <div
                        className="d-flex  card-div-crs"
                        style={{ width: "100%" }}
                      >
                        <div class="text-center">
                          <button
                            class="button text"
                            style={{ padding: "0px 6px", minWidth: "170px" }}
                            onClick={() => {
                              setCardValue(2);
                            }}
                          >
                            Add New Card
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="d-flex  card-div-crs "
                        style={{ width: "100%" }}
                      >
                        <h2>Card Details</h2>
                        <p>XXXX XXXX XXXX 6543</p>
                        <div class="text-center button-center pay-button">
                          <button
                            class="button text"
                            onClick={() => {
                              setCardValue(1);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {state?.cardDuration == "1" ? (
                  <div className="text_wrapper_w payments">
                    <div className="text_top d-flex">
                      <div className="d-flex align-center bookert">
                        <img src={images.train} />
                        <p className="booker">Booking Details</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex">
                        <h2>Journey Type</h2>
                        <p>One Day </p>
                      </div>
                      <div className="d-flex">
                        <h2>Booking Type</h2>
                        <p>Standard</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex space_edit">
                        <h2>Train No./Name</h2>
                        <p>12503(Fairy Queen)</p>
                      </div>
                      <div className="d-flex">
                        <h2>Booking Date</h2>
                        <p>01/01/2021</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex">
                        <h2>From Station</h2>
                        <p>Station 1</p>
                      </div>
                      <div className="d-flex">
                        <h2>To Station</h2>
                        <p>Station 2</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex">
                        <h2>Adult Count</h2>
                        <p>1</p>
                      </div>
                      <div className="d-flex">
                        <h2>Children Count</h2>
                        <p>1</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex">
                        <h2>Departure Time</h2>
                        <p>01:50 AM</p>
                      </div>
                      <div className="d-flex">
                        <h2>Arrival Time</h2>
                        <p>02:50 PM</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex permitted">
                        <span>
                          <img src={images.text_1} />
                        </span>
                        <h2>Travel is allowed via any permitted route.</h2>
                      </div>
                    </div>
                    <div className="first d-flex ppaymer_tags">
                      <div className="d-flex">
                        <h2>Total Fare</h2>
                        <h2>£70.00</h2>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text_wrapper_w payments">
                    <div className="text_top d-flex">
                      <div className="d-flex align-center bookert">
                        <img src={images.train} />
                        <p className="booker">Ship To</p>
                      </div>
                    </div>
                    <div className="first d-flex">
                      <div className="d-flex newtext">
                        <h2>Source Zone</h2>
                        <p>Station 1</p>
                      </div>
                      <div className="d-flex new_Data_t">
                        <h2>Destination Zone</h2>
                        <p>Standard 2</p>
                      </div>
                    </div>

                    <div className="first d-flex shipping_line_one">
                      <div className="d-flex">
                        <img src={images.wpf_name1} />
                        <h2>Mr. John Doe</h2>
                      </div>
                      <div className="d-flex">
                        <img src={images.entypo_address} />
                        <h2>
                          Manchester Piccadilly, Victoria, Oxford Road,
                          Deansgate
                        </h2>
                      </div>
                      <div className="d-flex last_data_k">
                        <img src={images.el_phone} />
                        <h2 style={{ fontWeight: 700 }}>+44 888 555 7774</h2>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-sm-6 left-paymnets">
                <div className="text_wrapper_w payments pay_div">
                  <div className="text_top d-flex">
                    <div className="d-flex align-center bookert">
                      {/* <img src={images.train} style={{visibility:'hidden'}} /> */}
                      <p className="booker">Fare Details</p>
                    </div>
                  </div>
                  <div className="first d-flex">
                    <div className="d-flex pay_text">
                      <h2>Ticket Fare</h2>
                      <p>£70.00</p>
                    </div>
                  </div>
                  <div className="first d-flex">
                    <div className="d-flex pay_text">
                      <h2>Total Fare </h2>
                      <p>£70.00</p>
                    </div>
                  </div>
                  <div class="text-center button-center">
                    <button
                      class="button text"
                      onClick={() => navigate("/myBookings")}
                    >
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMain />
    </div>
  );
};
export default PaymentShipping;
